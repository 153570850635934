<template>
  <div
   
    class="row cursor-pointer"
    :class="{ 'w-100': advertShowType === 'dashboard' }"
  >
    <div class="mb-3">
      <div class="ad"  @click="
      () => {
        $router.push({
          name: 'ilanlar.detay',
          query: { adId: detail.ID },
        });
      }
    " :style="detail.IsVisited == true ? 'background-color: #A6B3C5' : null">
        <div class="adDetails">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <span style="    font-size: 15px;"> #{{ detail.AdvertNo }} </span>
            </div>
            <div class="text-center hasOffer" v-if="detail.BidCount > 0">
              <span @click="advertsDialog = true" style="font-size: 12px">
                {{ detail.BidCount }}
                {{ user.IsCarrier ? "Teklif Verildi" : "Teklif" }}
              </span>
            </div>
            <div class="text-center passive" v-if="detail.Status === 6">
              <span @click="advertsDialog = true" style="font-size: 12px">
                Pasif
              </span>
            </div>
          </div>
          <span style="    font-size: 15px;">
            {{ detail.ProductType }}
          </span>
          <span>
            <div class="d-flex">
              <img src="../../assets/icons/nkicon/destination.svg" alt="" style="margin-right: 10px; width: 20px" />
              <div>
                <div class="d-inline">
                  <!-- <span
                    ><b style="color: #ffffff">Yükleme:</b>
                    {{ detail.Warehouse.DistrictTitle }}
                    | {{ detail.Warehouse.CityTitle }}
                  </span> -->
                   <span style="    font-size: 15px;"
                    ><b style="color: #ffffff">Yükleme:</b>
                    {{ detail.WarehouseDistrict }}
                    | {{ detail.WarehouseCity }}
                  </span>
                </div>
                &nbsp;
                <!-- <div class="d-inline">
                  <span
                    ><b style="color: #ffffff">İndirme:</b>
                    {{ detail.DeliveryCompany.DistrictTitle }}
                    | {{ detail.DeliveryCompany.CityTitle }}</span
                  >
                </div> -->
                <br>
                <div class="d-inline">
                  <span style="    font-size: 15px;"
                    ><b style="color: #ffffff">İndirme:</b>
                    {{ detail.DeliveryCompanyDistrict }}
                    | {{ detail.DeliveryCompanyCity}}</span
                  >
                </div>
              </div>
            </div>
          </span>
          <span>
            <img src="../../assets/icons/nkicon/calendar.svg" alt="" style="margin-right: 10px; width: 20px" />{{
              $moment(detail ? detail.AdvertDate : null)
                .add(1, "days")
                .format("LL")
            }}
          </span>
        </div>

        <div class="d-flex justify-content-center align-items-center" style="margin-right: 20px">
          <img src="../../assets/icons/nkicon/adddetail.svg" alt="" style="cursor: pointer; margin-right: 30px; width: 30px" />
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="ind % 3 == 0">
      <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-fb+5w+4e-db+86"
     data-ad-client="ca-pub-2915546448125125"
     data-ad-slot="9099951584"></ins>
     <div class="row mt-3">
      <div class="col-md-12 text-center">
        <el-button
									@click="gotoFastAdvs()"
									class="bg-primary text-white p-3 border-radius"
								>
									<span>Hızlı ilanlara git</span>
								</el-button>
      </div>
     </div>
<!-- <div class="ad" >
  <div class="adDetails">
    <div class="d-flex justify-content-center align-items-center">
      <div>
        <span>  </span>
      </div>
  
    </div>
    <span>

    </span>
    <span>
      <div class="d-flex">
     
        <div>
          <div class="d-inline">
     
             <span
              ><b style="color: #ffffff">Reklam ALANI:</b>
         
            </span>
          </div>
          &nbsp;
       
          <br>
          <div class="d-inline">
            <span
              ><b style="color: #ffffff">Reklam ALANI:</b>
          </span
            >
          </div>
        </div>
      </div>
    </span>
    <span>

    </span>
  </div>

  <div class="d-flex justify-content-center align-items-center" style="margin-right: 20px">
  
  </div>
</div> -->


</div>
  </div>
</template>

<script>
export default {
  props: ["detail", "advertShowType","ind"],
  data() {
    return {
      advertsDialog: false,
    };
  },
  mounted () {
    this.adsenseAddLoad();
},
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
  },
  methods:
  {
    adsenseAddLoad(){
        let inlineScript   = document.createElement("script");
        inlineScript.type  = "text/javascript";
        inlineScript.text  = '(adsbygoogle = window.adsbygoogle || []).push({});'
        document.getElementsByTagName('body')[0].appendChild(inlineScript);
    },
    gotoFastAdvs() {
      this.$router.push('/hizliilanlar');
    },
  }
};
</script>

<style>
.disabledAd {
  background-color: #d9d9d9;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}

.disabledAd span {
  color: #4d668a;
}

.ad {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
}
@media (max-width: 600px) {
  .adDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 7px;
  }
}
.adDetails span {
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.adDetails span:first-child {
  font-size: 22px;
  margin-left: 0px;
}

.adDetails span:nth-child(2) {
  font-size: 22px;
  margin-left: 0px;
}
.adDetails span:last-child {
  margin-bottom: 0;
}
.hasOffer {
  background-color: #82c0cc;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
.passive {
  background-color: #6d6d6d;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
@media (max-width: 380px) {
  .ad div:nth-child(2) img {
    margin-right: 0 !important;
  }
}
</style>
